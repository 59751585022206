import axios from "axios"
import { toast } from "@/toast"

const defaultState = () => ({
  searchResults: [],
  searchResultCustomer: {},
  searchTerm: "",
})

const checkStackStatusCreateOrUpdate = function (name) {
  return axios.post(`/supporter/stacks/${name}/status`, { accepted_states: ["CREATE_COMPLETE", "UPDATE_COMPLETE"] })
}

const support = {
  namespaced: true,
  state: defaultState(),
  mutations: {
    SET_SEARCH_RESULTS(state, results) {
      state.searchResults = results
    },
    SET_SEARCH_RESULT_CUSTOMER(state, results) {
      state.searchResultCustomer = results
    },
    SET_SEARCH_TERM(state, searchTerm) {
      state.searchTerm = searchTerm
    },
    ADD_PRTG_ADMIN_PASSWORD(state, { instanceId, password }) {
      state.searchResults = state.searchResults.map((result) => {
        if (result.instanceId === instanceId) {
          return {
            ...result,
            prtgAdminPassword: password,
          }
        }
        return result
      })
    },
    ADD_RDP_PASSWORD(state, { instanceId, password }) {
      state.searchResults = state.searchResults.map((result) => {
        if (result.instanceId === instanceId) {
          return {
            ...result,
            rdpPassword: password,
          }
        }
        return result
      })
    },
    UPDATE_EXPIRES_AT(state, { instanceId, trialEnd }) {
      state.searchResults = state.searchResults.map((result) => {
        if (result.instanceId === instanceId) {
          return {
            ...result,
            subscription: {
              ...result.subscription,
              expiresAt: trialEnd,
            },
          }
        }
        return result
      })
    },
    UPDATE_INSTANCE_TYPE(state, { instanceId, instanceType }) {
      state.searchResults = state.searchResults.map((result) => {
        if (result.instanceId === instanceId) {
          return {
            ...result,
            instanceType,
          }
        }
        return result
      })
    },
    RESET(state) {
      Object.assign(state, defaultState())
    },
  },
  actions: {
    async startSearch({ commit, dispatch }, query) {
      commit("RESET")

      if (query.email) {
        // it's important to call this above the other stuff to get the right toast message
        await dispatch("startSearchCustomer", query)
      }

      const fetchInstances
        = ({ name }) => axios.get(`/supporter/instances/${name}/getInstanceData`)
          .then(({ data: instance }) => instance)
          .catch(() => ({}))
      const fetchSubscriptions = ({ subscriptionId }) => axios.get(`/supporter/subscriptions/${subscriptionId}`)
        .then(({ data: subscription }) => subscription)
        .catch(() => ({}))
      const fetchUsers = ({ name }) => axios.get(`/supporter/users/${name}`)
        .then(({ data: users }) => users)
        .catch(() => ([]))
      const fetchUserHierarchy = ({ customerId }) => axios.get(`/supporter/customers/${encodeURI(customerId)}/hierarchy`)
        .then(({ data: hierarchy }) => hierarchy)
        .catch(() => ([]))
      const fetchIncongruities = ({ name }) => axios.get(`/supporter/stacks/${name}/incongruities`)
        .then(({ data: incongruities }) => incongruities)
        .catch(() => ({}))

      try {
        const { data: stacks } = await axios.post("/supporter/stacks", query)
        const everything = await Promise.all(stacks.map(async (stack) => {
          const [instance, incongruities, subscription, users, hierarchy] = await Promise.all([
            fetchInstances(stack),
            fetchIncongruities(stack),
            fetchSubscriptions(stack),
            fetchUsers(stack),
            fetchUserHierarchy(stack),
          ])
          return { ...stack, instance, incongruities, subscription, users, hierarchy }
        }))
        commit("SET_SEARCH_RESULTS", everything)
      }
      catch (e) {
        toast.error(e)
      }
    },
    async startSearchCustomer({ commit }, query) {
      try {
        const fetchCustomerAuth = () => axios.get("/supporter/customers", { params: query })
          .then(({ data: customerAuth0 }) => customerAuth0)
        const fetchCustomerChargebee = query => axios.get("/supporter/customers2", { params: query })
          .then(({ data: customerChargebee }) => customerChargebee)

        const [customerChargebee, customerAuth0] = await Promise.all([
          fetchCustomerChargebee(query),
          fetchCustomerAuth(query),
        ])

        commit("SET_SEARCH_RESULT_CUSTOMER", { ...customerChargebee, ...customerAuth0 })
      }
      catch (e) {
        toast.error(e)
      }
    },
    async getPrtgAdminPassword({ commit, state }, { instanceId, name }) {
      const { prtgAdminPassword } = state.searchResults.find(result => result.instanceId === instanceId)
      if (prtgAdminPassword) {
        return
      }

      try {
        const { data } = await axios.get(`/supporter/instances/${name}/admin_password`)
        commit("ADD_PRTG_ADMIN_PASSWORD", { ...data, instanceId })
      }
      catch (e) {
        toast.error(e)
      }
    },
    async grantRemoteAccess({ commit, state }, { subdomain, instanceId, name }) {
      const { rdpPassword } = state.searchResults.find(result => result.instanceId === instanceId)
      if (rdpPassword) {
        return
      }

      try {
        const { data } = await axios.post(`/supporter/instances/${name}/ports/rdp/open`, { subdomain })
        commit("ADD_RDP_PASSWORD", { ...data, instanceId })
      }
      catch (e) {
        toast.error(e)
      }
    },
    async extendSubscription({ commit }, { name, instanceId, subscriptionId, date, agentComment }) {
      try {
        await checkStackStatusCreateOrUpdate(name)
        const newDate = Math.floor(new Date(Date.parse(date)).getTime() / 1000)
        const { data } = await axios.post(`/supporter/subscriptions/${subscriptionId}/extend`, {
          trial_end: newDate,
          agent_comment: agentComment,
        })
        commit("UPDATE_EXPIRES_AT", { ...data, instanceId })
        toast.success(`Trial subscription ${subscriptionId} now ends at ${new Date(data.trialEnd * 1000).toUTCString()}.`)
      }
      catch (e) {
        toast.error(e)
      }
    },
    async resizeInstance({ commit }, { name, instanceId, instanceType }) {
      try {
        await checkStackStatusCreateOrUpdate(name)
        const { data } = await axios.post(`/supporter/stacks/${name}/resize`, { instance_type: instanceType })
        commit("UPDATE_INSTANCE_TYPE", { ...data, instanceId })
        toast.success(`Resize of instance ${instanceId} to ${data.instanceType} scheduled.`)
      }
      catch (e) {
        toast.error(e)
      }
    },
  },
}

export {
  support,
}
