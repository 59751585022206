import axios from "axios"

const visitor = {
  namespaced: true,
  state: {
    visitor: {},
    // rewrite logic if we want to support more currencies
    countriesEUR: [
      "AD", "AL", "AT", "AX", "BA", "BE", "BG", "CH", "CZ", "DE", "DK", "EE", "ES", "FI",
      "FR", "GB", "GF", "GG", "GI", "GP", "GR", "HR", "HU", "IC", "IE", "IM", "IS", "IT", "JE",
      "LI", "LT", "LU", "LV", "MC", "ME", "MK", "MQ", "MT", "NC", "NL", "NO", "PL", "PM", "PT", "RE", "RO", "RS",
      "SE", "SI", "SK", "SM", "TF", "TR", "UK", "VA", "WF", "YT",
    ],
  },
  mutations: {
    SET_VISITOR(state, data) {
      state.visitor = {
        countryCode: "",
        currencyCode: "USD",
        latitude: null,
        longitude: null,
      }

      const ipInfo = data.ip_info
      if (ipInfo) {
        state.visitor.countryCode = ipInfo.countryCode
        if (state.countriesEUR.includes(state.visitor.countryCode)) {
          state.visitor.currencyCode = "EUR"
        }
        state.visitor.latitude = ipInfo.lat
        state.visitor.longitude = ipInfo.lon
      }
    },
  },
  actions: {
    async fetchVisitor({ commit, state }) {
      if (Object.keys(state.visitor).length === 0) {
        const instance = axios.create({})
        const { data } = await instance.get(
          "https://n3k5k32e00.execute-api.eu-central-1.amazonaws.com/production/userinfo",
          { baseUrl: "" },
        )
        commit("SET_VISITOR", data)
      }
    },
  },
}

export {
  visitor,
}
